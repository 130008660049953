// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.anime-item-info {
    position: absolute;
    top: -5px;
    left: 160px;
    min-width: 600px;
    width: auto;
    height: auto;
    background-color: rgba(33, 42, 79, 1);
    border: 1px solid #ccc;
    padding: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 9999;
    border-radius: 20px;
}

.anime-item-info-title {
    font-size: 18px;
    font-weight: bold;
    color: white;
    margin-bottom: 10px;
}

.anime-item-card-img-skeleton {
    position: "absolute";
    top: 0;
    left: 0;
    z-index: 1;
    background-color: #3a4f63;
}

.rating-badge { 
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 14px;
    font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/Components/Main/AnimeItemMiniCard.css"],"names":[],"mappings":";AACA;IACI,kBAAkB;IAClB,SAAS;IACT,WAAW;IACX,gBAAgB;IAChB,WAAW;IACX,YAAY;IACZ,qCAAqC;IACrC,sBAAsB;IACtB,aAAa;IACb,uCAAuC;IACvC,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,oBAAoB;IACpB,MAAM;IACN,OAAO;IACP,UAAU;IACV,yBAAyB;AAC7B;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,UAAU;IACV,oCAAoC;IACpC,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;IAClB,eAAe;IACf,iBAAiB;AACrB","sourcesContent":["\n.anime-item-info {\n    position: absolute;\n    top: -5px;\n    left: 160px;\n    min-width: 600px;\n    width: auto;\n    height: auto;\n    background-color: rgba(33, 42, 79, 1);\n    border: 1px solid #ccc;\n    padding: 10px;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n    z-index: 9999;\n    border-radius: 20px;\n}\n\n.anime-item-info-title {\n    font-size: 18px;\n    font-weight: bold;\n    color: white;\n    margin-bottom: 10px;\n}\n\n.anime-item-card-img-skeleton {\n    position: \"absolute\";\n    top: 0;\n    left: 0;\n    z-index: 1;\n    background-color: #3a4f63;\n}\n\n.rating-badge { \n    position: absolute;\n    top: 10px;\n    left: 10px;\n    background-color: rgba(0, 0, 0, 0.7);\n    color: white;\n    padding: 5px 10px;\n    border-radius: 5px;\n    font-size: 14px;\n    font-weight: bold;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
