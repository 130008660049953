// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-select option {
    background-color: rgba(33, 42, 79, 1);;
}

.form-select {
    color: white;
    min-height: 2.5em;
    border: 1px solid rgba(33, 42, 79, 1);
}

.remove-button {
    position: absolute;
    right: 0;
    margin: 0 !important;
    background-color: transparent;
    border: none;
    width: auto;
}`, "",{"version":3,"sources":["webpack://./src/Components/Main/Filter.css"],"names":[],"mappings":"AAAA;IACI,qCAAqC;AACzC;;AAEA;IACI,YAAY;IACZ,iBAAiB;IACjB,qCAAqC;AACzC;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,oBAAoB;IACpB,6BAA6B;IAC7B,YAAY;IACZ,WAAW;AACf","sourcesContent":[".form-select option {\n    background-color: rgba(33, 42, 79, 1);;\n}\n\n.form-select {\n    color: white;\n    min-height: 2.5em;\n    border: 1px solid rgba(33, 42, 79, 1);\n}\n\n.remove-button {\n    position: absolute;\n    right: 0;\n    margin: 0 !important;\n    background-color: transparent;\n    border: none;\n    width: auto;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
