// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sign-in {
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    padding-bottom: 10rem;
    padding-top: 10rem;
}

.sign-in form {
    background-color: #9393c944;
    padding: 20px;
    border-radius: 20px;
    box-shadow: -1px -1px 10px 10px rgba(34, 60, 80, 0.658);
}

.error-message {
    color: rgb(248, 67, 67);
}

.socials {
    margin-bottom: 20px;
}

.socials a {
    padding: 0 0 0 10px;
}

.reset-password {
    padding-top: 10px;
}

.reset-password a {
    color: rgb(206, 203, 203);
    text-decoration: none;
}`, "",{"version":3,"sources":["webpack://./src/Pages/SignInPage.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,YAAY;IACZ,qBAAqB;IACrB,kBAAkB;AACtB;;AAEA;IACI,2BAA2B;IAC3B,aAAa;IACb,mBAAmB;IACnB,uDAAuD;AAC3D;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,yBAAyB;IACzB,qBAAqB;AACzB","sourcesContent":[".sign-in {\n    width: auto;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    color: white;\n    padding-bottom: 10rem;\n    padding-top: 10rem;\n}\n\n.sign-in form {\n    background-color: #9393c944;\n    padding: 20px;\n    border-radius: 20px;\n    box-shadow: -1px -1px 10px 10px rgba(34, 60, 80, 0.658);\n}\n\n.error-message {\n    color: rgb(248, 67, 67);\n}\n\n.socials {\n    margin-bottom: 20px;\n}\n\n.socials a {\n    padding: 0 0 0 10px;\n}\n\n.reset-password {\n    padding-top: 10px;\n}\n\n.reset-password a {\n    color: rgb(206, 203, 203);\n    text-decoration: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
