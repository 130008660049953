// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::-webkit-scrollbar {
    display: none;
}

.button {
    position: relative;
    background-color: transparent;
    border: 0;
    font-size: 16px;
    color: white;
    padding: 0.3em 0.6em;
    cursor:pointer;
    -webkit-user-select:none;
            user-select:none;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    transition-duration: 0.4s;
    -webkit-transition-duration: 0.4s;
    border-radius: 20px;
}

.button:hover {
    transition-duration: 0.1s;
    background-color: #9393c944;
    box-shadow: -1px -1px 10px 10px rgba(34, 60, 80, 0.658);
}

.mini-block {
    background-color: gray;
    padding: 4px;
    border-radius: 20px;
    font-size: 14px;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,kBAAkB;IAClB,6BAA6B;IAC7B,SAAS;IACT,eAAe;IACf,YAAY;IACZ,oBAAoB;IACpB,cAAc;IACd,wBAAgB;YAAhB,gBAAgB;IAChB,kBAAkB;IAClB,qBAAqB;IACrB,eAAe;IACf,yBAAyB;IACzB,iCAAiC;IACjC,mBAAmB;AACvB;;AAEA;IACI,yBAAyB;IACzB,2BAA2B;IAC3B,uDAAuD;AAC3D;;AAEA;IACI,sBAAsB;IACtB,YAAY;IACZ,mBAAmB;IACnB,eAAe;AACnB","sourcesContent":["::-webkit-scrollbar {\n    display: none;\n}\n\n.button {\n    position: relative;\n    background-color: transparent;\n    border: 0;\n    font-size: 16px;\n    color: white;\n    padding: 0.3em 0.6em;\n    cursor:pointer;\n    user-select:none;\n    text-align: center;\n    text-decoration: none;\n    cursor: pointer;\n    transition-duration: 0.4s;\n    -webkit-transition-duration: 0.4s;\n    border-radius: 20px;\n}\n\n.button:hover {\n    transition-duration: 0.1s;\n    background-color: #9393c944;\n    box-shadow: -1px -1px 10px 10px rgba(34, 60, 80, 0.658);\n}\n\n.mini-block {\n    background-color: gray;\n    padding: 4px;\n    border-radius: 20px;\n    font-size: 14px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
