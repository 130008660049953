// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kVtMtA {
    opacity: 0;
    background-color: #9393c9d2;
    color: white;
    left: 1.5rem;
}

.bhRnqO {
    opacity: 0;
    background-color: #9393c9cc;
    right: 1.5rem;
}

.bhRnqO::before {
    border-top: 2px solid white;
    border-right: 2px solid white;
}

.kVtMtA::before {
    content: '';
    height: 10px;
    width: 10px;
    background: transparent;
    border-top: 2px solid white;
    border-right: 2px solid white;
    display: inline-block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-25%, -50%) rotate(-135deg);
}

.bghjHX {
    margin: 0;
}

.bqyzQp:hover .kVtMtA {
    opacity: 1;
}

.bqyzQp:hover .bhRnqO {
    opacity: 1;
}

`, "",{"version":3,"sources":["webpack://./src/Components/Main/AnimeList.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,2BAA2B;IAC3B,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,UAAU;IACV,2BAA2B;IAC3B,aAAa;AACjB;;AAEA;IACI,2BAA2B;IAC3B,6BAA6B;AACjC;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,WAAW;IACX,uBAAuB;IACvB,2BAA2B;IAC3B,6BAA6B;IAC7B,qBAAqB;IACrB,kBAAkB;IAClB,QAAQ;IACR,SAAS;IAGT,gDAAgD;AACpD;;AAEA;IACI,SAAS;AACb;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,UAAU;AACd","sourcesContent":[".kVtMtA {\n    opacity: 0;\n    background-color: #9393c9d2;\n    color: white;\n    left: 1.5rem;\n}\n\n.bhRnqO {\n    opacity: 0;\n    background-color: #9393c9cc;\n    right: 1.5rem;\n}\n\n.bhRnqO::before {\n    border-top: 2px solid white;\n    border-right: 2px solid white;\n}\n\n.kVtMtA::before {\n    content: '';\n    height: 10px;\n    width: 10px;\n    background: transparent;\n    border-top: 2px solid white;\n    border-right: 2px solid white;\n    display: inline-block;\n    position: absolute;\n    top: 50%;\n    left: 50%;\n    -webkit-transform: translate(-25%, -50%) rotate(-135deg);\n    -ms-transform: translate(-25%, -50%) rotate(-135deg);\n    transform: translate(-25%, -50%) rotate(-135deg);\n}\n\n.bghjHX {\n    margin: 0;\n}\n\n.bqyzQp:hover .kVtMtA {\n    opacity: 1;\n}\n\n.bqyzQp:hover .bhRnqO {\n    opacity: 1;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
