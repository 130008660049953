// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spinner-text {
    color: white;
    font-weight: bold;
    font-size: 2rem;
    margin-top: 2rem;
}`, "",{"version":3,"sources":["webpack://./src/Components/Loading/Loading.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,iBAAiB;IACjB,eAAe;IACf,gBAAgB;AACpB","sourcesContent":[".spinner-text {\n    color: white;\n    font-weight: bold;\n    font-size: 2rem;\n    margin-top: 2rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
