// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error {
    text-align: center;
    color: white;
    margin-top: 20rem;
    margin-bottom: 20rem;
}

.error .number {
    font-weight: bold;
}

.btn {
    background-color: rgba(33,42,79,1);
    color: white;
    transition: .2s;
    width: 100px;
}

.btn:hover {
    background-color: rgba(33,42,79,1);
    border: 1px solid white;
}`, "",{"version":3,"sources":["webpack://./src/Components/Error/Error.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,YAAY;IACZ,iBAAiB;IACjB,oBAAoB;AACxB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,kCAAkC;IAClC,YAAY;IACZ,eAAe;IACf,YAAY;AAChB;;AAEA;IACI,kCAAkC;IAClC,uBAAuB;AAC3B","sourcesContent":[".error {\n    text-align: center;\n    color: white;\n    margin-top: 20rem;\n    margin-bottom: 20rem;\n}\n\n.error .number {\n    font-weight: bold;\n}\n\n.btn {\n    background-color: rgba(33,42,79,1);\n    color: white;\n    transition: .2s;\n    width: 100px;\n}\n\n.btn:hover {\n    background-color: rgba(33,42,79,1);\n    border: 1px solid white;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
