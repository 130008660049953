// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.footer {
    background-color: transparent;
    color: rgb(168, 165, 165);
}

.footer a {
    text-decoration: none;
    color: rgb(168, 165, 165);
}`, "",{"version":3,"sources":["webpack://./src/Components/Footer/Footer.css"],"names":[],"mappings":";AACA;IACI,6BAA6B;IAC7B,yBAAyB;AAC7B;;AAEA;IACI,qBAAqB;IACrB,yBAAyB;AAC7B","sourcesContent":["\n.footer {\n    background-color: transparent;\n    color: rgb(168, 165, 165);\n}\n\n.footer a {\n    text-decoration: none;\n    color: rgb(168, 165, 165);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
