// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.anime-catalog {
    display: flex;
    justify-content: space-between;
    margin-left: 100px;
    margin-right: 100px;
    align-items: flex-start;
}

.anime-catalog-list {
    justify-content: space-between;
    color: white;
    width: 100%;
}

.anime-catalog-filter {
    padding: 10px;
    display: flex;
    color: white;
    width: 500px;
    height: auto;
    text-align: center;
}

.anime-catalog-title {
    text-align: center;
}

.form-select {
    height: 2rem;
    font-size: 15px;
    background-color: rgba(62, 117, 143, 0.342);
    color: white;
}

.anime-catalog-items {
    display: flex;
    flex-wrap: wrap;
}

.anime-item-card {
    margin: 10px;
    width: auto;
    border-radius: 100px;
}

.anime-item-card-img {
    border-radius: 10px;
}

.anime-item-card-img:hover {
    transform: scale(1.05);
    transition: 0.5s;
}

.anime-item-card {
    position: relative;
}

.anime-catalog-empty {
    text-align: center;
    color: white;
    margin-top: 50px;
    width: 100%;
    font-size: 30px;
}`, "",{"version":3,"sources":["webpack://./src/Components/Main/Catalog.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,kBAAkB;IAClB,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,8BAA8B;IAC9B,YAAY;IACZ,WAAW;AACf;;AAEA;IACI,aAAa;IACb,aAAa;IACb,YAAY;IACZ,YAAY;IACZ,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,eAAe;IACf,2CAA2C;IAC3C,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,oBAAoB;AACxB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,sBAAsB;IACtB,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,gBAAgB;IAChB,WAAW;IACX,eAAe;AACnB","sourcesContent":[".anime-catalog {\n    display: flex;\n    justify-content: space-between;\n    margin-left: 100px;\n    margin-right: 100px;\n    align-items: flex-start;\n}\n\n.anime-catalog-list {\n    justify-content: space-between;\n    color: white;\n    width: 100%;\n}\n\n.anime-catalog-filter {\n    padding: 10px;\n    display: flex;\n    color: white;\n    width: 500px;\n    height: auto;\n    text-align: center;\n}\n\n.anime-catalog-title {\n    text-align: center;\n}\n\n.form-select {\n    height: 2rem;\n    font-size: 15px;\n    background-color: rgba(62, 117, 143, 0.342);\n    color: white;\n}\n\n.anime-catalog-items {\n    display: flex;\n    flex-wrap: wrap;\n}\n\n.anime-item-card {\n    margin: 10px;\n    width: auto;\n    border-radius: 100px;\n}\n\n.anime-item-card-img {\n    border-radius: 10px;\n}\n\n.anime-item-card-img:hover {\n    transform: scale(1.05);\n    transition: 0.5s;\n}\n\n.anime-item-card {\n    position: relative;\n}\n\n.anime-catalog-empty {\n    text-align: center;\n    color: white;\n    margin-top: 50px;\n    width: 100%;\n    font-size: 30px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
