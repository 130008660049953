// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-avatar{
    width: 200px;
}

.card {
    margin-left: 100px;
    margin-right: 100px;
    border: 1px solid rgb(114, 113, 113);
    border-radius: 12px;
    filter: brightness(110%);
    background-color: rgba(52, 52, 56, 0.192);
    color: white;
}

.card-header {
    border-bottom: rgb(114, 113, 113) solid 2px;
}`, "",{"version":3,"sources":["webpack://./src/Components/Main/Profile.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;IACnB,oCAAoC;IACpC,mBAAmB;IACnB,wBAAwB;IACxB,yCAAyC;IACzC,YAAY;AAChB;;AAEA;IACI,2CAA2C;AAC/C","sourcesContent":[".card-avatar{\n    width: 200px;\n}\n\n.card {\n    margin-left: 100px;\n    margin-right: 100px;\n    border: 1px solid rgb(114, 113, 113);\n    border-radius: 12px;\n    filter: brightness(110%);\n    background-color: rgba(52, 52, 56, 0.192);\n    color: white;\n}\n\n.card-header {\n    border-bottom: rgb(114, 113, 113) solid 2px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
