// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main {
  padding: 1.5rem;
}

.anime-card {
  color: white;
  text-align: center;
  margin-bottom: 30px;
  box-shadow: 0 0 2px 2px black;
  border-radius: 10px;
}

.title {
  padding: 0.5rem;
  font-weight: bold;
  font-size: 20px;
  background-color: #9393c944;
  border-radius: 10px 10px 0 0;
}`, "",{"version":3,"sources":["webpack://./src/Pages/MainPage.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,mBAAmB;EACnB,6BAA6B;EAC7B,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,eAAe;EACf,2BAA2B;EAC3B,4BAA4B;AAC9B","sourcesContent":[".main {\n  padding: 1.5rem;\n}\n\n.anime-card {\n  color: white;\n  text-align: center;\n  margin-bottom: 30px;\n  box-shadow: 0 0 2px 2px black;\n  border-radius: 10px;\n}\n\n.title {\n  padding: 0.5rem;\n  font-weight: bold;\n  font-size: 20px;\n  background-color: #9393c944;\n  border-radius: 10px 10px 0 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
